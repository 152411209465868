.App {
    text-align: center;
}

.App-header {
    background-color: #000000;
    min-height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    font-size: calc(10px + 2vmin);
    color: white;
}

.Lottie {
    width: 300px;
    height: 100px;
    margin-bottom: 0;
}

.Main-p {
    font-size: 22px;
    margin-top: 25px;
    margin-bottom: 40px;
}

.Hidden-p {
    font-size: 18px;
    margin-top: 20px;
    margin-bottom: 0;
    max-width: 300px;
}

.Main-a {
    font-size: 22px;
    margin-top: 25px;
    margin-bottom: 0px;
}

.Link-p {
    font-size: 24px;
    margin-top: 40;
}

.Link-a {
    margin-left: 8px;
    margin-right: 8px;
}

a {
    color: #FFFFFF;
    text-decoration: none;
}

a:hover {
    color: #FFFFFF;
    text-decoration: none;
    cursor: pointer;
}
